import React from "react"
import Layout from "../components/shared/layout/layout"
import SEO from "../components/shared/ui-elements/seo"
import ContactsContent from "../components/home/contacts/contacts"
import {useIntl} from "gatsby-plugin-intl";

const Contacts = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO title={intl.formatMessage({id: `seo.contacts.title`})}
                 description={intl.formatMessage({id: `seo.contacts.desc`})}/>
            <ContactsContent/>
        </Layout>
    );
}

export default Contacts
