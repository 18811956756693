import React from "react"
import "./contacts.scss"
import Breadcrumbs from "../../shared/breadcrumbs/breadcrumbs"
import {useIntl} from "gatsby-plugin-intl"

export const ContactsContent = (props) => {
    const intl = useIntl()
    return (
        <div className="contacts-container">
            <Breadcrumbs data={[{name: intl.formatMessage({id: `header.contacts`})}]}/>
            <div className="container">
                <h1 className="contact-title">{intl.formatMessage({id: `contacts.title`})}</h1>
                <div className="separator"/>
                <div className="contact-info">
                    <p>{intl.formatMessage({id: `contacts.contact_info_1`})}</p>
                    <p>{intl.formatMessage({id: `contacts.contact_info_2`})}</p>
                    <p>{intl.formatMessage({id: `contacts.contact_info_3`})}</p>
                    <p>
                        {intl.formatMessage({id: `contacts.contact_info_phone`})}
                        <a href="tel:(096) 099-00-99">{intl.formatMessage({id: `contacts.phone_1`})}</a>
                    </p>
                    <p>
                        {intl.formatMessage({id: `contacts.contact_info_phone`})}
                        <a href="tel:(032) 295-63-00">{intl.formatMessage({id: `contacts.phone_2`})}</a>
                    </p>
                    <p>
                        {intl.formatMessage({id: `contacts.email`})}
                        <a href="mailto:bayris@bayris.ua">bayris@bayris.ua.</a>
                    </p>
                </div>
                <form method="post" action="https://formspree.io/arovenskyi@gmail.com" className="contact-form">
                    <div className="contact-form-user-data">
                        <h4 className="contact-form-title">{intl.formatMessage({id: `contacts.sendMessage`})}</h4>
                        <div className="contact-input-content">
                            <label htmlFor="name">{intl.formatMessage({id: `contacts.name`})}</label>
                            <input required type="text" id="name" name="name"/>
                        </div>
                        <div className="contact-input-content">
                            <label htmlFor="email">{intl.formatMessage({id: `contacts.address`})}</label>
                            <input pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required id="email"
                                   type="email" name="email"/>
                        </div>
                        <div className="contact-input-content">
                            <label htmlFor="subject">{intl.formatMessage({id: `contacts.them`})}</label>
                            <input required type="text" id="subject" name="type"/>
                        </div>
                        <div className="contact-input-content textarea">
                            <label htmlFor="message">{intl.formatMessage({id: `contacts.message`})}</label>
                            <textarea required name="message"/>
                        </div>
                        <div className="user-agreement">
                            <div className="user-agreement-text">
                                <input required className="agreement-input" type="checkbox"/>
                                {intl.formatMessage({id: `contacts.terms`})}
                            </div>
                            <button id="submitBtn"
                                    className="send-btn disabled">{intl.formatMessage({id: `contacts.send`})}</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default ContactsContent
